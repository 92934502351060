<template>
  <div>
    <v-row>
      <h1 class="ml-4">Companies</h1>
    </v-row>

    <v-card class="mt-3">
      <v-card-title>
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="search"
              placeholder="Type a company's name to search..."
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              outlined
              color="#F87368"
              class="mr-2"
              @click="toggleFilterMenu"
            >
              <v-icon color="f87368">mdi-view-column-outline</v-icon>Add columns
            </v-btn></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="filteredHeaders"
          :items="companies.records"
          :loading="isLoading"
          :search="search"
          transition="scale-transition"
          class="new-detault-table companies-view"
        >
          <template v-slot:top>
            <div>
              <v-row>
                <v-col cols="9"></v-col>
                <v-col cols="3">
                  <company-upload-file @refresh="handleUploadSuccess" />
                  <company-create @companies-updated="handleUploadSuccess" />
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              x-small
              dark
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn fab x-small dark @click="deleteItem(item)" color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.documents="{ item }">
            <div v-for="document in item.documents" :key="document.id">
              <v-btn
                x-small
                dark
                color="blue"
                class="ma-2"
                :href="document.publitoUrl"
                target="_blank"
              >
                {{ document.name }}
                <v-icon class="ml-2">mdi-download</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :style="getStatusStyle(item.status)" x-small pill>
              {{ item.status ? "Active" : "Inactive" }}
            </v-chip>
          </template>

          <template v-slot:item.updatedAt="{ item }">
            <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-menu v-model="showMenu" :position-x="menuX" :position-y="menuY">
      <v-list>
        <v-list-item-group>
          <v-list-item v-for="column in headers" :key="column.text">
            <v-checkbox v-model="column.show" :label="column.text"></v-checkbox>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import defaultProfile from "../../../assets/img/avatar.png";
import CompanyUploadFile from "../../../components/Base/CompanyUploadFile.vue";
import CompanyCreate from "../../../components/Base/CompanyCreate.vue";
import PublitioAPI from "publitio_js_sdk";

export default {
  name: "ViewCompanies",
  components: {
    defaultProfile,
    CompanyUploadFile,
    CompanyCreate,
  },
  data() {
    return {
      fields: {
        "Company Name": "name",
        "Company Id": "id",
        "Created At": "createdAt",
      },
      publitio: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          show: true,
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
          show: true,
        },
        {
          text: "Reg. Number",
          align: "start",
          sortable: false,
          value: "companyReg",
          show: false,
        },
        {
          text: "Contact person",
          align: "start",
          sortable: false,
          value: "companyContactPerson",
          show: true,
        },
        {
          text: "Contact email",
          align: "start",
          sortable: false,
          value: "companyContactPersonEmail",
          show: false,
        },
        {
          text: "documents",
          align: "start",
          sortable: false,
          value: "documents",
          show: true,
        },
        {
          text: "Actions",
          align: "right",
          sortable: false,
          value: "actions",
          show: true,
        },
        { text: "Created Date", value: "createdAt", show: false },
      ],
      search: "",
      companies: [],
      totalRecords: 0,
      isLoading: false,
      showMenu: false,
      menuX: 0,
      menuY: 0,
    };
  },

  apollo: {
    documents: {
      query: gql`
        query Documents($limit: Int!, $skip: Int!, $query: JSON!) {
          documents(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              publitoUrl
              name
              publitoDownloadUrl
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const limit = 3000;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
    companies: {
      query: gql`
        query Companies($limit: Int!, $skip: Int!, $query: JSON!) {
          companies(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              companyReg
              notes
              status
              companyContactPerson
              companyContactPersonEmail
              fullGoogleAddress
              documents {
                id
                publitoUrl
                name
                publitoDownloadUrl
              }
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    editItem(item) {
      this.$router.push(`/admin/company/${item.id}/edit`);
    },
    toggleFilterMenu(event) {
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      this.showMenu = !this.showMenu;
    },

    getStatusStyle(status) {
      return status
        ? { background: "green", color: "white" }
        : { background: "red", color: "white" };
    },

    async handleUploadSuccess() {
      await this.$apollo.queries.documents.refetch();
      await this.$apollo.queries.companies.refetch();
    },

    async deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteCompany(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "The Company was successfully deleted",
                "success"
              );
              await this.$apollo.queries.companies.refetch();
            }
          } catch (e) {}
        }
      });
    },
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((column) => column.show);
    },
  },
  created() {
    this.publitio = new PublitioAPI(
      process.env.VUE_APP_PUBLITO_API_KEY,
      process.env.VUE_APP_PUBLITO_API_SECRET
    );
  },
};
</script>

<style>
.green-chip {
  background-color: green;
  color: white;
}

.red-chip {
  background-color: red;
  color: white;
}
</style>
